import type DropInElement from '@adyen/adyen-web/dist/types/components/Dropin'
import { PaymentMethodsResponse } from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse/types'
import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types'
import { AdyenPayment, CommerceLayerClient, Order } from '@commercelayer/sdk'
import { useRef, useState } from 'react'
import { getClassNames, getTranslations } from '../../config'
import { ResultCode, useAdyen } from '../../hooks/use-adyen'
import { useClient } from '../../hooks/use-commerce-layer'
import { useCurrentOrder } from '../../hooks/use-order'
import { redirect } from '../../lib/routing'
import { LinkMessage } from './LinkMessage'

type Props = {
  onSubmit: (order: Order) => void | Promise<void>
  onError?: (message: string) => void | Promise<void>
}

export const CheckoutPayment = ({ onSubmit }: Props) => {
  const classes = getClassNames('checkout')
  const translations = getTranslations('checkout').stepPayment

  const { order } = useCurrentOrder()
  const client = useClient()
  const dropInRef = useRef<DropInElement | null>(null)
  const [isLoading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const adyenPayment = order?.payment_source as AdyenPayment | undefined
  const clientKey = adyenPayment?.public_key ?? ''
  const adyenResponse = adyenPayment?.payment_methods as PaymentMethodsResponse | undefined

  const locale = window.commerceConfig.locale ?? 'en-US'

  const { paymentContainer } = useAdyen({
    clientKey,
    locale,
    order,
    paymentMethods: adyenResponse?.paymentMethods,
    onReady: (dropIn) => {
      dropInRef.current = dropIn
    },
    // When you select another payment method
    onSelect: (state) => {
      setIsValid(state.isValid)
    },
    // When an input within a payment method changes
    onChange: (state) => {
      setIsValid(state.isValid)
    },
    // When special data is required such as 3D security
    onAdditionalDetails: async (state) => {
      if (!order || !dropInRef.current) {
        return
      }
      const paymentResponse = await client.adyen_payments
        .update({
          id: order?.payment_source?.id as string,
          payment_request_details: state.data,
          // @ts-ignore
          _details: 1,
        })
        .then((r) => r.payment_response as CheckoutSessionPaymentResponse)

      const returnUrl = getCheckoutCompleteUrl(order)

      await handleResponse(paymentResponse, dropInRef.current, returnUrl, setIsValid)
    },
    onSubmit: async (values: any) => {
      if (!order || !dropInRef.current) {
        return
      }
      setLoading(true)

      const returnUrl = getCheckoutCompleteUrl(order)

      const paymentRequestData = getPaymentRequestData({ values, returnUrl, locale })

      // Send payment request (ideal or credit card data)
      const paymentResponse = order.placed_at
        ? ((order.payment_source as any)?.payment_response as CheckoutSessionPaymentResponse)
        : await makePaymentRequest({ client, order, paymentRequestData })

      await onSubmit({
        ...order,
        payment_source: {
          ...(order.payment_source as AdyenPayment),
          payment_request_data: paymentRequestData,
          payment_response: paymentResponse,
        },
      })

      // Handle payment response
      await handleResponse(paymentResponse, dropInRef.current, returnUrl, setIsValid)

      setLoading(false)
    },
  })

  return (
    <div className={classes.stepPayment.root}>
      <div ref={paymentContainer} />

      <LinkMessage
        messageTemplate={translations.termsMessage}
        url={window.commerceConfig.urls.termsAndConditions}
        className={classes.stepPayment.termsMessage}
      />
      <div className={classes.form.actions}>
        <button
          type="submit"
          onClick={() => dropInRef.current?.submit()}
          disabled={!isValid || !dropInRef.current || isLoading}
          aria-busy={isLoading}
          className={classes.form.submitButton}
        >
          {translations.submit}
        </button>
      </div>
    </div>
  )
}

/**
 * Makes the configured return url absolute and appends an order id query param
 */
const getCheckoutCompleteUrl = (order: Order) => {
  const checkoutCompleteUrl = new URL(window.commerceConfig.urls.completed, window.location.origin)
  checkoutCompleteUrl.searchParams.set('order', order.id)

  return checkoutCompleteUrl.toString()
}

const getPaymentRequestData = ({
  values,
  returnUrl,
  locale,
}: {
  values: any
  returnUrl: string
  locale: string
}) => ({
  payment_method: values.data.paymentMethod,
  shopperInteraction: 'Ecommerce',
  recurringProcessingModel: 'CardOnFile',
  origin: window.location.origin,
  return_url: returnUrl,
  redirect_from_issuer_method: 'GET',
  checkoutAttemptId: values.data.checkoutAttemptId,
  clientStateDataIndicator: values.data.clientStateDataIndicator,
  riskData: values.data.riskData,
  browser_info: {
    acceptHeader:
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    screenWidth: window?.screen?.width ?? '',
    screenHeight: window?.screen?.height ?? '',
    colorDepth: window?.screen?.colorDepth ?? '',
    userAgent: window?.navigator?.userAgent ?? '',
    timeZoneOffset: new Date().getTimezoneOffset(),
    language: locale,
    javaEnabled: false,
  },
})

const makePaymentRequest = async ({
  order,
  client,
  paymentRequestData,
}: {
  order: Order
  client: CommerceLayerClient
  paymentRequestData: any
}) => {
  // Send payment request (ideal or credit card data)
  await client.adyen_payments.update({
    id: order.payment_source!.id,
    order: client.orders.relationship(order),
    payment_request_data: paymentRequestData,
  })

  // Authorize immediately (has to be a separate request) to get a payment response
  return client.adyen_payments
    .update({
      id: order.payment_source!.id,
      // @ts-ignore
      _authorize: 1,
    })
    .then((r) => r.payment_response as CheckoutSessionPaymentResponse)
}

// https://docs.adyen.com/online-payments/payment-result-codes

const handleResponse = async (
  paymentResponse: CheckoutSessionPaymentResponse,
  component: DropInElement,
  returnUrl: string,
  setValid: (value: boolean) => void
) => {
  const resultCode = paymentResponse.resultCode as ResultCode
  switch (resultCode) {
    case 'Authorised':
    case 'Pending':
    case 'Received':
      // Redirect to checkout complete page
      redirect(returnUrl)
      break
    case 'IdentifyShopper':
    case 'ChallengeShopper':
      // Handle additional checks (e.g. password, 3D secure, etc.)
      component.handleAction(paymentResponse.action!)
      break
    case 'RedirectShopper':
      // Redirect to shopper
      redirect(paymentResponse.action!.url!)
      break
    case 'Refused':
    case 'Cancelled':
    case 'Error':
    default:
      console.debug(paymentResponse)

      component.setStatus('error', { message: errorMessage(resultCode) })
      setValid(false)

      window.setTimeout(() => {
        component.setStatus('ready')
        setValid(true)
      }, 5000)
      break
  }
}

const errorMessage = (resultCode: ResultCode) => {
  switch (resultCode) {
    case 'Refused':
      return 'De CVC, vervaldatum of wachtwoord is incorrect, probeer het opnieuw of kies een andere betaalmethode.'
    case 'Cancelled':
      return 'De betaling is geannuleerd, probeer het opnieuw of kies een andere betaalmethode.'
    case 'Error':
    default:
      return 'De betaling is mislukt, probeer het opnieuw of kies een andere betaalmethode.'
  }
}
