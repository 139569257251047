import { Order } from '@commercelayer/sdk'
import { HTMLAttributes } from 'react'
import { getClassNames, getTranslations } from '../config'
import { useCurrentOrder } from '../hooks/use-order'
import { GiftCardOrCouponCode } from './GiftCardOrCouponCode'
import Money from './Money'

type Props = {
  order: Order
  /**
   * This value will overrule the existing shipping cost.
   * It Can be used to preview shipping cost before a shipping method is saved.
   */
  estimatedShippingCost?: number
  showGiftCardOrCouponCodeForm?: boolean
} & HTMLAttributes<HTMLTableElement>

export const PriceTable = ({
  order,
  estimatedShippingCost,
  showGiftCardOrCouponCodeForm,
  ...attributes
}: Props) => {
  const translations = getTranslations('priceTable')
  const classes = getClassNames('priceTable')

  const showDiscountField = window.commerceConfig?.featureFlags?.showDiscountField

  const { updateOrder } = useCurrentOrder()

  const currency = order.currency_code!

  const shippingCosts =
    // if we have an estimated shipping cost, we show that as a preview
    estimatedShippingCost ??
    // otherwise, we show the current shipping costs from the order
    order.shipping_amount_cents ??
    // as a fallback, we show the first available shipping costs
    order.shipments?.[0]?.available_shipping_methods?.[0]?.price_amount_cents ??
    0

  const subTotalCents = order.subtotal_amount_cents ?? 0
  const totalCents =
    (order.total_amount_with_taxes_cents || order.total_amount_cents || 0) +
    // if we have shipping costs, we add them to the total
    (order.shipping_amount_cents ? 0 : shippingCosts)

  return (
    <table {...attributes}>
      <tbody>
        <tr>
          <th>{translations.subTotal}</th>
          <td>
            <Money centAmount={subTotalCents} currency={currency} />
          </td>
        </tr>

        {order.shipments?.[0]?.available_shipping_methods && (
          <tr className={classes.smallItem}>
            <th>{translations.shipmentCosts}</th>
            <td>
              <Money centAmount={shippingCosts} currency={currency} />
            </td>
          </tr>
        )}

        {showDiscountField && (
          <>
            {order.coupon_code && (
              <tr className={classes.smallItem}>
                <th>
                  {order.coupon_code}

                  {showGiftCardOrCouponCodeForm && (
                    <button
                      className={classes.couponRemove}
                      type="button"
                      onClick={() => updateOrder({ id: order.id }, { couponCode: '' })}
                      aria-label={translations.removeCoupon}
                    >
                      <span>X</span>
                    </button>
                  )}
                </th>
                <td>
                  {order.discount_amount_cents && (
                    <Money centAmount={order.discount_amount_cents} currency={currency} />
                  )}
                </td>
              </tr>
            )}
            {order.gift_card_code && (
              <tr className={classes.smallItem}>
                <th>
                  {order.gift_card_code}
                  {showGiftCardOrCouponCodeForm && (
                    <button
                      className={classes.couponRemove}
                      type="button"
                      onClick={() => updateOrder({ id: order.id }, { giftCardCode: '' })}
                      aria-label={translations.removeGiftCard}
                    >
                      <span>X</span>
                    </button>
                  )}
                </th>
                <td>
                  {order.gift_card_amount_cents && (
                    <Money centAmount={order.gift_card_amount_cents} currency={currency} />
                  )}
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>

      {/* Coupon form */}
      {showDiscountField && showGiftCardOrCouponCodeForm && (
        <tbody>
          <tr>
            <th colSpan={2}>
              <GiftCardOrCouponCode />
            </th>
          </tr>
        </tbody>
      )}
      {/* end coupon form */}

      <tfoot>
        <tr>
          <th>
            {translations.total} <small>{translations.taxInformation}</small>
          </th>
          <td>
            <Money centAmount={totalCents} currency={currency} />
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
