import { useEffect, useState } from 'react'
import { Loader } from '../components/Loader'
import Money from '../components/Money'
import { AddressSummary } from '../components/checkout/AddressSummary'
import { CheckoutAddresses } from '../components/checkout/CheckoutBillingAddress'
import { CheckoutOverview } from '../components/checkout/CheckoutOverview'
import { CheckoutPayment } from '../components/checkout/CheckoutPayment'
import { CheckoutShipment } from '../components/checkout/CheckoutShipment'
import { LinkMessage } from '../components/checkout/LinkMessage'
import { EstimatedShippingCostProvider } from '../components/checkout/ShippingCost'
import { Step } from '../components/checkout/Step'
import { getClassNames, getTranslations } from '../config'
import { CheckoutTranslations } from '../config/types'
import { Metadata, useCurrentOrder } from '../hooks/use-order'
import { orderMetadataToClDealerAddress, useShippingMethods } from '../hooks/use-shipping-methods'
import {
  addPaymentInfoEvent,
  addShippingInfoEvent,
  beginCheckoutEvent,
  fireEvent,
} from '../lib/analytics'
import { redirect } from '../lib/routing'
import { ShippingMethodId } from '../lib/shipping-method'

function CheckoutWidget() {
  const classes = getClassNames('checkout')
  const translations = getTranslations('checkout')
  const { order, isLoading } = useCurrentOrder()
  const [serverError, setServerError] = useState<string>()

  // initially no step is open (steps start at 1)
  const [step, setStep] = useState(0)

  const {
    deliveryMatchId,
    shippingMethods,
    isLoading: isShippingMethodsLoading,
  } = useShippingMethods(order?.id, order?.shipping_address?.zip_code)

  const selectedShippingMethod: ShippingMethodId = order?.metadata?.shipping_method

  // Set initial step, or redirect to cart page when order is loaded
  useEffect(() => {
    if (isLoading) {
      return
    }

    // Redirect to cart if order is empty
    if (!order?.line_items?.length) {
      redirect(window.commerceConfig.urls.cart)
      return
    }

    // Set initial step when order is loaded
    const step = !order.billing_address
      ? 1
      : !order.shipments?.[0]?.shipping_method || !order.shipping_address
        ? 2
        : 3

    // Fire begin checkout event when loaded on step 1
    if (step === 1 && order) {
      fireEvent(beginCheckoutEvent(order))
    }
    setStep(step)
  }, [isLoading])

  if (!order) {
    return null
  }

  return (
    <EstimatedShippingCostProvider>
      <div className={classes.root}>
        <div>
          <h1>
            {translations.title}
            {order.total_amount_cents ? (
              <Money centAmount={order.total_amount_cents} currency={order.currency_code!} />
            ) : undefined}
          </h1>

          {/* Billing step */}
          <Step
            current={step}
            value={1}
            onChangeStep={setStep}
            getToggleChildren={(isExpanded) => (
              <>
                <div>
                  <h2>{translations.stepBilling.title}</h2>
                  {isExpanded && <p>{translations.stepBilling.description}</p>}
                </div>
                {!isExpanded && (
                  <div className={classes.stepAddress.summary}>
                    <div>
                      <h3>{translations.stepBilling.email}</h3>
                      <div>{order?.customer_email}</div>
                    </div>

                    {order?.billing_address && (
                      <AddressSummary
                        title={translations.stepBilling.billingTitle}
                        address={order.billing_address}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          >
            <CheckoutAddresses onSubmit={() => setStep(2)} />
          </Step>

          {/* Shipment step */}
          <Step
            current={step}
            value={2}
            onChangeStep={setStep}
            getToggleChildren={(isExpanded) => (
              <>
                <div>
                  <h2>{translations.stepShipping.title}</h2>
                  {step < 3 ? <p>{translations.stepShipping.description}</p> : undefined}
                </div>

                {!isExpanded && (
                  <div className={classes.stepShipping.summary}>
                    {selectedShippingMethod === 'home-delivery' && order?.shipping_address && (
                      <AddressSummary
                        title={toShipmentSummaryTitle(selectedShippingMethod, translations)}
                        address={order.shipping_address}
                      />
                    )}
                    {['dealer-pickup', 'courier-pickup'].includes(selectedShippingMethod) &&
                      order?.metadata?.shipping_method && (
                        <AddressSummary
                          title={toShipmentSummaryTitle(selectedShippingMethod, translations)}
                          address={orderMetadataToClDealerAddress(order?.metadata as Metadata)}
                        />
                      )}
                  </div>
                )}
              </>
            )}
          >
            {shippingMethods && !isShippingMethodsLoading ? (
              <CheckoutShipment
                onSubmit={(order) => {
                  fireEvent(addShippingInfoEvent(order))
                  return setStep(3)
                }}
                deliveryMatchId={deliveryMatchId ?? ''}
                shippingMethods={shippingMethods}
              />
            ) : (
              <Loader />
            )}
          </Step>

          {/* Payment step */}
          <Step
            current={step}
            value={3}
            onChangeStep={setStep}
            getToggleChildren={() => (
              <div>
                <h2>{translations.stepPayment.title}</h2>
                <p>{translations.stepPayment.description}</p>
              </div>
            )}
          >
            <CheckoutPayment
              onSubmit={(order) => fireEvent(addPaymentInfoEvent(order))}
              onError={(code) =>
                setServerError(
                  code === 'out_of_stock'
                    ? translations.stepPayment.outOfStockError
                    : 'Something went wrong'
                )
              }
            />

            {serverError && (
              <LinkMessage
                className={classes.error}
                messageTemplate={serverError}
                url={window.commerceConfig.urls.cart}
              />
            )}
          </Step>
        </div>

        <CheckoutOverview
          order={order}
          action={<a href={window.commerceConfig.urls.cart}>{translations.overview.edit}</a>}
        />
      </div>
    </EstimatedShippingCostProvider>
  )
}

export default CheckoutWidget

const toShipmentSummaryTitle = (
  shippingMethod: ShippingMethodId,
  translations: CheckoutTranslations
) => {
  switch (shippingMethod) {
    case 'dealer-pickup':
      return translations.stepShipping.dealer
    case 'courier-pickup':
      return translations.stepShipping.parcelShop
    default:
      return translations.stepShipping.shippingTitle
  }
}
