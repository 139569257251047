import { CommerceLayer } from '@commercelayer/sdk'
import { JSX } from 'react'
import useSWR from 'swr'
import { CommerceLayerClientContext } from '../hooks/use-commerce-layer'
import { fetchToken, getTokenFromCookie, storeToken } from '../lib/auth'
import { ViewEvents } from './ViewEvents'

const MINUTES = 60_000

type Props = {
  children: JSX.Element | JSX.Element[]
}

export const CommerceProvider = ({ children }: Props) => {
  const oldToken = getTokenFromCookie()
  const { data: token } = useSWR(
    'token',
    () => fetchToken(window.commerceConfig.commerceLayer).then(storeToken),
    {
      keepPreviousData: true,
      fallbackData: oldToken && Date.now() > oldToken.expires_at ? undefined : oldToken,
      refreshInterval: 10 * MINUTES,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (!token) {
    return null
  }

  const client = CommerceLayer({
    accessToken: token.access_token,
    organization: window.commerceConfig.commerceLayer.endpoint
      .replace('https://', '')
      .split('.')[0],
  })

  return (
    <CommerceLayerClientContext.Provider value={client}>
      <ViewEvents />
      {children}
    </CommerceLayerClientContext.Provider>
  )
}
