import { CheckoutSessionPaymentResponse } from '@adyen/adyen-web/dist/types/types'
import { AdyenPayment, Order } from '@commercelayer/sdk'
import { useEffect } from 'react'
import { CheckoutOverview } from '../components/checkout/CheckoutOverview'
import { getOrderKey } from '../config'
import { ResultCode } from '../hooks/use-adyen'
import { useCurrentOrder, useOrder } from '../hooks/use-order'
import { fireEvent, purchaseEvent } from '../lib/analytics'
import { shippingMethodType } from '../lib/shipping-method'

/**
 * Uses the order id from the URL instead of from the local storage.
 * This is so that we can see the order details even if is not the current order anymore.
 *
 * After a successful payment a purchase event is fired, and the order is placed at CommerceLayer and removed from the local storage.
 *
 * Furthermore, it will toggle the visibility of the success/failure content after a payment status check.
 */
function CheckoutCompleteWidget() {
  const params = new URLSearchParams(window.location.search)

  const id = params.get('order')
  const redirectResult = params.get('redirectResult') ?? undefined

  const { order, placeOrder, saveRedirectResult } = useOrder(id)
  const { mutate } = useCurrentOrder()

  useEffect(() => {
    if (!order) {
      return
    }

    document.body.setAttribute('data-delivery', isDealerDelivery(order) ? 'dealer' : 'home')

    if (hasDealerRequiredItems(order)) {
      document.body.setAttribute('data-dealer-required', 'true')
    }

    checkPaymentStatus(order, redirectResult, saveRedirectResult).then(async (paymentSuccess) => {
      document.body.setAttribute('data-payment-status', paymentSuccess ? 'success' : 'failure')

      if (paymentSuccess && !order.placed_at) {
        const placedOrder = await placeOrder()

        if (placedOrder) {
          fireEvent(purchaseEvent(placedOrder))

          if (placedOrder.id === window.localStorage.getItem(getOrderKey())) {
            window.localStorage.removeItem(getOrderKey())
            mutate()
          }
        }
      }
    })
  }, [Boolean(order)])

  return (
    <div>{order && <CheckoutOverview order={order} showGiftCardOrCouponCodeForm={false} />}</div>
  )
}

export default CheckoutCompleteWidget

const OK_PAYMENT_STATUSES: Order['payment_status'][] = ['authorized', 'paid']

const ERROR_RESPONSE_STATUSES: ResultCode[] = ['Cancelled', 'Refused', 'Error']

async function checkPaymentStatus(
  order: Order,
  redirectResult: string | undefined,
  saveRedirectResult: (value: string) => Promise<ResultCode>
): Promise<boolean> {
  const paymentSource = order.payment_source as AdyenPayment | undefined
  const paymentResponse = paymentSource?.payment_response as
    | CheckoutSessionPaymentResponse
    | undefined

  // Check if there is an existing payment result code
  if (
    paymentResponse &&
    ERROR_RESPONSE_STATUSES.includes(paymentResponse.resultCode as ResultCode)
  ) {
    return false
  }

  // Save a redirectResult if it is not already saved, and check that payment result code
  if (
    redirectResult &&
    (paymentSource?.payment_request_details as any)?.details?.redirectResult !== redirectResult
  ) {
    const resultCode = await saveRedirectResult(redirectResult)

    return !ERROR_RESPONSE_STATUSES.includes(resultCode)
  }

  return OK_PAYMENT_STATUSES.includes(order.payment_status)
}

const hasDealerRequiredItems = (order: Order) =>
  order.line_items?.some(
    (li) =>
      li.item?.type === 'skus' &&
      li.item.shipping_category?.id === window.commerceConfig.shippingCategories.dealerRequiredItems
  )

const isDealerDelivery = (order: Order) => {
  const method = order.shipments?.[0]?.shipping_method
  return method && shippingMethodType(method) === 'dealer'
}
