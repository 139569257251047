import { LineItem, Order, Sku } from '@commercelayer/sdk'
import { sentenceCase } from 'change-case'

/**
 * Fires Google Analytics v4 events
 *
 * For more information see:
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 *
 */

export const fireEvent = ({
  eventName,
  eventDetails,
}: {
  eventName: string
  eventDetails: Record<string, unknown>
}) => {
  if ('dataLayer' in window) {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({ event: eventName, ecommerce: eventDetails })
  } else {
    console.debug('analytic event:', eventName, eventDetails)
  }
}

export const viewItemListEvent = (skus: Sku[], list: string) => ({
  eventName: 'view_item_list',
  eventDetails: {
    item_list_id: list,
    item_list_name: sentenceCase(list),
    items: skus.map(skuToItem),
  },
})

export const viewItemEvent = (sku: Sku) => ({
  eventName: 'view_item',
  eventDetails: {
    currency: sku.prices?.[0].currency_code,
    value: sku.prices?.[0].amount_float,
    items: [skuToItem(sku)],
  },
})

export const addToCartEvent = (sku: Sku) => ({
  eventName: 'add_to_cart',
  eventDetails: {
    currency: sku.prices?.[0].currency_code,
    value: sku.prices?.[0].amount_float,
    items: [skuToItem(sku)],
  },
})

export const removeFromCartEvent = (lineItem: LineItem) => ({
  eventName: 'remove_from_cart',
  eventDetails: {
    currency: lineItem.total_amount_float,
    value: lineItem.currency_code,
    items: [lineItemToItem(lineItem)],
  },
})

export const viewCartEvent = (order: Order) => ({
  eventName: 'view_cart',
  eventDetails: {
    coupon: order?.coupon_code,
    currency: order?.currency_code,
    value: order?.total_amount_with_taxes_float,
    items: order.line_items
      ?.filter((l) => LINE_ITEM_SHOP_TYPES.includes(l.item_type!))
      .map(lineItemToItem),
  },
})

export const beginCheckoutEvent = (order: Order) => ({
  eventName: 'begin_checkout',
  eventDetails: {
    coupon: order?.coupon_code,
    currency: order?.currency_code,
    value: order?.total_amount_with_taxes_float,
    items: order.line_items
      ?.filter((l) => LINE_ITEM_SHOP_TYPES.includes(l.item_type!))
      .map(lineItemToItem),
  },
})

export const addShippingInfoEvent = (order: Order) => ({
  eventName: 'add_shipping_info',
  eventDetails: {
    coupon: order?.coupon_code,
    currency: order?.currency_code,
    items: order.line_items
      ?.filter((l) => LINE_ITEM_SHOP_TYPES.includes(l.item_type!))
      .map(lineItemToItem),
    value: order.shipments?.[0].shipping_method?.price_amount_for_shipment_float,
    shipping_tier: order.shipments?.[0].shipping_method?.name,
  },
})

export const addPaymentInfoEvent = (order: Order) => ({
  eventName: 'add_payment_info',
  eventDetails: {
    coupon: order?.coupon_code,
    currency: order?.currency_code,
    items: order.line_items
      ?.filter((l) => LINE_ITEM_SHOP_TYPES.includes(l.item_type!))
      .map(lineItemToItem),
    value: order.payment_method?.price_amount_float,
    payment_type: (
      order.payment_source as { payment_request_data?: { payment_method?: { type?: string } } }
    )?.payment_request_data?.payment_method?.type,
  },
})

export const purchaseEvent = (order: Order) => ({
  eventName: 'purchase',
  eventDetails: {
    coupon: order?.coupon_code,
    currency: order?.currency_code,
    items: order.line_items
      ?.filter((l) => LINE_ITEM_SHOP_TYPES.includes(l.item_type!))
      .map(lineItemToItem),
    transaction_id: order?.number,
    shipping: order?.shipping_amount_float,
    value: order?.total_amount_with_taxes_float,
    tax: order?.total_tax_amount_float,
  },
})

const LINE_ITEM_SHOP_TYPES = ['skus', 'bundles', 'gift_cards']

const lineItemToItem = (lineItem: LineItem) => ({
  item_id: lineItem.sku_code ?? lineItem.bundle_code,
  item_name: lineItem.name,
  price: lineItem.total_amount_float,
  currency: lineItem.currency_code,
  quantity: lineItem.quantity,
})

const skuToItem = (sku: Sku) => ({
  item_id: sku.code,
  item_name: sku.metadata?.product_name ?? sku.name,
  price: sku.prices?.[0].amount_float,
  currency: sku.prices?.[0].currency_code,
})
