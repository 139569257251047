import { Price } from '@commercelayer/sdk'
import useSWR from 'swr'
import { useClient } from './use-commerce-layer'

export const usePrice = (sku: string) => {
  const client = useClient()

  const { data: price, ...rest } = useSWR<Price | undefined, any, string>(`price/${sku}`, (_) =>
    sku
      ? client.prices
          .list({
            filters: { sku_code_eq: sku },
            pageSize: 1,
          })
          .then((items) => items[0])
      : undefined
  )

  return {
    price,
    ...rest,
  }
}
