import { Order } from '@commercelayer/sdk'
import { clsx } from 'clsx'
import { HTMLAttributes, ReactNode } from 'react'
import { getClassNames, getTranslations } from '../../config'
import LineItems from '../LineItems'
import { PriceTable } from '../PriceTable'
import { useEstimatedShippingCost } from './ShippingCost'

type Props = {
  order: Order
  action?: ReactNode
  showGiftCardOrCouponCodeForm?: boolean
} & HTMLAttributes<HTMLDivElement>

export const CheckoutOverview = ({
  order,
  action,
  showGiftCardOrCouponCodeForm = true,
  ...attributes
}: Props) => {
  const classes = getClassNames('checkout')
  const translations = getTranslations('checkout')

  const { estimatedShippingCost } = useEstimatedShippingCost()

  return (
    <div {...attributes} className={clsx(classes.overview, attributes.className)}>
      <header>
        <div>{translations.title}</div>
        {action}
      </header>

      <LineItems order={order} />

      <PriceTable
        order={order}
        estimatedShippingCost={estimatedShippingCost}
        showGiftCardOrCouponCodeForm={showGiftCardOrCouponCodeForm}
      />
    </div>
  )
}
