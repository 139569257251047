import { Address, AddressCreate } from '@commercelayer/sdk'
import { zip } from './collection'

const ignoreKeys = ['id', 'updated_at', 'created_at']
export const isSameAddress = (address1: Address, address2: Address) =>
  zip(
    Object.entries(address1)
      .filter(([key]) => !ignoreKeys.includes(key))
      .flatMap(([, value]) => (value instanceof Object ? Object.values(value) : value)),
    Object.entries(address2)
      .filter(([key]) => !ignoreKeys.includes(key))
      .flatMap(([, value]) => (value instanceof Object ? Object.values(value) : value))
  ).every(([v1, v2]) => v1 === v2)

export const fetchAddress = (
  baseUrl: string,
  apiKey: string,
  zipCode: string,
  houseNumber: string
) =>
  fetch(
    `${baseUrl}/1/postalcode/autocomplete?apikey=${apiKey}&postalcode=${zipCode}&streetnumber=${houseNumber}`
  ).then<LouwmanAddressResponse>((resp) => {
    if (resp.status === 200) return resp.json()
    else throw new Error('Invalid response')
  })

export type AddressType = 'billingAddress' | 'shippingAddress'

export type FormAddress = {
  salutation: string
  firstName: string
  lastNamePreposition?: string
  lastName: string
  street: string
  houseNumber: string
  houseNumberAddition?: string
  company?: string
  zipCode: string
  city: string
  phone: string
}

type LouwmanAddressResponse = {
  status: string
  results: LouwmanAddress[]
}

type LouwmanAddress = {
  postalcode: string
  street: string
  city: string
  municipality: string
  province: string
  streetnumbers: string
  lat: number
  lng: number
  areacode: string
}

export const sanitizeZipCode = (input: string) =>
  input
    // From a group of 4 numbers and a group of 2 numbers separated by any spaces
    // remove all spaces and add a single space between groups.
    // The i-flag makes the regex case insensitive.
    .replace(/([0-9]+)\s*([A-Z]+)/gi, '$1 $2')
    // Then, transform the string to uppercase and trim it.
    .toUpperCase()
    .trim()

/**
 * Transform first letter of string to uppercase
 *
 * @param value - The string to transform
 */
export const capitalize = (value: string): string => value.charAt(0).toUpperCase() + value.slice(1)

/**
 * A pattern to validate a Dutch phone number
 *
 * Complete for all house numbers and mobile numbers.
 *
 * - Spaces may occur everywhere.
 * - Dashes only after the regional part
 * - After the dash only 1-9,
 * - In case of 3 of 4 numbers in the regional part, no starting 6 or 0 is allowed
 *
 * Examples of valid numbers
 *
 * @example
 * 0031 6 12345678
 * 31 6 12345678
 * 06 12345678
 * 06-12345678
 * 0612345678
 * +31 6 12345678
 * 020 1234567
 */
export const phonePattern =
  /^(?:0|(?:\+|00)? ?31 ?)(?:(?:[1-9] ?(?:[0-9] ?){8})|(?:6 ?-? ?[1-9] ?(?:[0-9] ?){7})|(?:[1,2,3,4,5,7,8,9]\d ?-? ?[1-9] ?(?:[0-9] ?){6})|(?:[1,2,3,4,5,7,8,9]\d{2} ?-? ?[1-9] ?(?:[0-9] ?){5}))$/

/**
 * Changes a phone number that passes `phonePattern` to the normalized Dutch format.
 *
 * E.g. `+31 612345678`
 */
export const normalizedPhoneNumber = (value: string) =>
  '+31 ' +
  value
    // remove all white spaces
    .replace(/[\s-]/g, '')
    // remove a starting 0 (if any)
    .replace(/^0/, '')
    // remove a starting country code (if any)
    .replace(/^(?:[+0]+)?31 ?0?/, '')

export const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const formAddressToApiAddress = (address: FormAddress): AddressCreate => ({
  city: address.city,
  country_code: 'NL',
  line_1: `${address.street} ${address.houseNumber}${address.houseNumberAddition ?? ''}`,
  phone: normalizedPhoneNumber(address.phone),
  state_code: 'X',
  metadata: {
    street: address.street,
    houseNumber: address.houseNumber,
    houseNumberAddition: address.houseNumberAddition,
    lastNamePreposition: address.lastNamePreposition,
    salutation: address.salutation,
  },
  zip_code: address.zipCode,
  first_name: address.firstName,
  last_name: address.lastName,
  company: address.company,
})
