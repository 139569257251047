import { Sku } from '@commercelayer/sdk'
import useSWR from 'swr'
import { useClient } from './use-commerce-layer'
import { useCurrentOrder } from './use-order'

const include = ['prices', 'stock_items', 'shipping_category', 'sku_options']

export const useProduct = (sku: string, option?: string) => {
  const client = useClient()
  const { mutate, order, createOrder } = useCurrentOrder()

  const { data: product, ...rest } = useSWR<Sku | undefined, Error, string>(`sku/${sku}`, () =>
    sku
      ? client.skus
          .list({
            filters: { code_eq: sku },
            pageSize: 1,
            include,
          })
          .then((items) => items[0])
      : undefined
  )

  return {
    product,
    addToCart: async (quantity: number) => {
      if (!product) {
        console.error(`no sku`)
        return
      }
      const currentOrder = order ?? (await createOrder())

      const lineItem = await client.line_items
        .create({
          name: product?.metadata?.product_name ?? product?.name,
          image_url: product?.image_url,
          quantity,
          _update_quantity: true,
          order: client.orders.relationship(currentOrder),
          item: client.skus.relationship(product),
        })
        .then((r) => {
          // refetch the order object
          mutate(
            (order) => ({
              ...order!,
              line_items: [...order!.line_items!.filter((l) => l.id !== r.id), r],
            }),
            { revalidate: false }
          )
          return r
        })

      const skuOption = product.sku_options?.find((o) => o.reference === option)
      if (skuOption) {
        await client.line_item_options.create({
          line_item: client.line_items.relationship(lineItem),
          sku_option: client.sku_options.relationship(skuOption),
          quantity: 1,
          options: {},
          metadata: {},
        })
      }

      return lineItem
    },
    ...rest,
  }
}
