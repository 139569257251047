export const getSearchLocation = (map: google.maps.Map, query: string) =>
  new Promise<google.maps.places.PlaceGeometry | undefined>((resolve) =>
    new google.maps.places.PlacesService(map).findPlaceFromQuery(
      {
        fields: ['name', 'geometry'],
        query,
        locationBias: CENTER_OF_NETHERLANDS,
      },
      (results) => {
        resolve(results?.[0]?.geometry ?? undefined)
      }
    )
  )

export const CENTER_OF_NETHERLANDS = { lat: 52.229223, lng: 5.459644 }
