import { ApiError, Order } from '@commercelayer/sdk'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { object, string } from 'yup'
import { getClassNames, getTranslations } from '../config'
import { GiftCardOrCouponCodeFormTranslations } from '../config/types'
import { useCurrentOrder } from '../hooks/use-order'
import { Form } from './form/Form'
import { InputField } from './form/InputField'

export const GiftCardOrCouponCode = () => {
  const classes = getClassNames('giftCardOrCouponCodeForm')
  const translations = getTranslations('giftCardOrCouponCodeForm')
  const { order, updateOrder } = useCurrentOrder()
  const [couponError, setCouponError] = useState<string | undefined>(undefined)

  const schema = object({
    giftCardOrCouponCode: string().required(translations.required),
  })

  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  return !order?.coupon_code || !order?.gift_card_code ? (
    <details className={classes.root}>
      <summary className={classes.summary}>{translations.title}</summary>
      <div>
        <Form
          form={form}
          translations={translations}
          onSubmit={async (values) => {
            if (!order) return

            setCouponError(undefined)

            updateOrder({ id: order.id }, { giftCardOrCouponCode: values.giftCardOrCouponCode })
              .then(() => {
                form.reset()
              })
              .catch((error) => {
                const hasError = (error as ApiError).errors[0].code === 'VALIDATION_ERROR'

                if (hasError) {
                  setCouponError(translations.error)
                } else {
                  throw error
                }
              })
          }}
          serverError={couponError}
        >
          <InputField
            label={translations.label}
            name="giftCardOrCouponCode"
            type="text"
            data-required
            placeholder={getDiscountInputPlaceholder(order, translations)}
          />
        </Form>
      </div>
    </details>
  ) : null
}

// Determine placeholder based on the order state
const getDiscountInputPlaceholder = (
  order: Order | undefined,
  translations: GiftCardOrCouponCodeFormTranslations
) => {
  if (order?.coupon_code) {
    return translations.placeholderGiftCard // Enter gift card
  }
  if (order?.gift_card_code) {
    return translations.placeholderCoupon // Enter coupon
  }
  return translations.placeholder
}
