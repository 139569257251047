import { ObjectSchema, boolean, mixed, object, string } from 'yup'
import { CheckoutTranslations } from '../../config/types'
import { FormAddress, phonePattern } from '../../lib/address'
import { ListOption, LocationOption, ShippingMethod } from '../../lib/shipping-method'
import { ShippingFormValues } from './CheckoutShipment'

export const getShippingMethodSchema = (
  translations: CheckoutTranslations,
  shippingMethods: ShippingMethod[]
): ObjectSchema<ShippingFormValues> =>
  object({
    shippingMethod: string()
      .oneOf(shippingMethods.map((method) => method.id))
      .required(translations.stepShipping.validate.selectShippingMethod),
    differentAddress: boolean().default(false),
    shippingAddress: getAddressSchema(translations).when(
      'differentAddress',
      ([differentAddress]) =>
        differentAddress ? getAddressSchema(translations).required() : mixed()
    ),
    methodId: string().when('shippingMethod', ([shippingMethod]) => {
      return string()
        .nullable()
        .test(
          'methodId',
          translations.stepShipping.validate.selectShippingMethodOption,
          (value) => {
            // Check if the selected shipping method has the selected method ID.
            const shippingMethodData = shippingMethods.find(
              (method) => method.id === shippingMethod
            )

            if (shippingMethodData?.type === 'list') {
              return Boolean(
                shippingMethodData?.options.find((option: ListOption) => option.id === value)
              )
            } else if (shippingMethodData?.type === 'location') {
              return Boolean(
                shippingMethodData?.options.find((option: LocationOption) => option.id === value)
              )
            }
          }
        )
    }),
  })

export const getAddressSchema = (translations: CheckoutTranslations): ObjectSchema<FormAddress> =>
  object({
    salutation: string()
      .default('')
      .required(translations.address.validate.required)
      .oneOf(['male', 'female'], translations.address.validate.required),
    firstName: string().required(translations.address.validate.required).default(''),
    lastNamePreposition: string().default('').label(translations.address.lastNamePreposition),
    lastName: string().required(translations.address.validate.required).default(''),
    zipCode: string()
      .required(translations.address.validate.required)
      .matches(/^\d{4} ?[a-z]{2}$/i, translations.address.validate.zipCode)
      .default(''),
    houseNumber: string()
      .required(translations.address.validate.required)
      .matches(/^[0-9]*$/, translations.address.validate.houseNumber)
      .default(''),
    houseNumberAddition: string().default('').label(translations.address.houseNumberAddition),
    street: string().required(translations.address.validate.required).default(''),
    city: string().required(translations.address.validate.required).default(''),
    phone: string()
      .required(translations.address.validate.required)
      .matches(phonePattern, translations.address.validate.phoneNumber)
      .default(''),
    company: string().default('').label(translations.address.company),
  })
