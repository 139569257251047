import {
  AddToCartButtonClassNames,
  AvailabilityClassNames,
  CartClassNames,
  CartCounterClassNames,
  CartQuickViewClassNames,
  CartSkeletonClassnames,
  CheckoutClassNames,
  ClassNames,
  GiftCardOrCouponCodeFormClassNames,
  LineItemsClassNames,
  LoaderClassNames,
  MoneyClassNames,
  PriceClassNames,
  PriceTableClassNames,
} from './types'

export const defaultAddToCartButtonClassNames: AddToCartButtonClassNames = {
  root: 'AddToCartButton',
  added: 'AddToCartButton-added',
  icon: 'AddToCartButton-icon',
  stockMessage: 'AddToCartButton-stockMessage',
}
export const defaultAvailabilityClassNames: AvailabilityClassNames = {
  root: 'Availability',
  stock: 'Availability-stock',
  hasStock: 'Availability-hasStock',
  outOfStock: 'Availability-outOfStock',
}

export const defaultCartClassNames: CartClassNames = {
  root: 'Cart',

  outOfStockError: 'Cart-outOfStockError',
  quantityError: 'Cart-quantityError',
  dealerWarning: 'Cart-dealerWarning',

  lineItems: 'Cart-lineItems',
  lineItemsContent: 'Cart-lineItemsContent',
  lineItemsHeader: 'Cart-lineItemsHeader',
  lineItemsRemove: 'Cart-lineItemsRemove',
  lineItemsBody: 'Cart-lineItemsBody',
  selectContainer: 'Cart-selectContainer',
  select: 'Cart-select',

  aside: 'Cart-aside',
  costOverview: 'Cart-costOverview',
  proceedButton: 'Cart-proceedButton',
  paymentDescription: 'Cart-paymentDescription',

  empty: 'Cart-empty',
}
export const defaultCartCounterClassNames: CartCounterClassNames = {
  root: 'CartCounter',
}

export const defaultCartQuickViewClassNames: CartQuickViewClassNames = {
  root: 'CartQuickView',
  scroll: 'CartQuickView-scroll',
  notice: 'CartQuickView-notice',
  actions: 'CartQuickView-actions',
  proceedCartButton: 'CartQuickView-proceedCartButton',
  proceedShoppingButton: 'CartQuickView-proceedShoppingButton',
  backDrop: 'CartQuickView-backdrop',
}

export const defaultLoaderClassNames: LoaderClassNames = {
  root: 'Loader',
}

export const defaultCouponCodeFormClassNames: GiftCardOrCouponCodeFormClassNames = {
  root: 'CouponCodeForm',
  summary: 'CouponCodeForm-summary',
}

export const defaultPriceTableClassNames: PriceTableClassNames = {
  couponRemove: 'PriceTable-couponRemove',
  smallItem: 'PriceTable-smallItem',
}

export const defaultLineItemsClassnames: LineItemsClassNames = {
  root: 'LineItems',
  header: 'LineItems-header',
  title: 'LineItems-title',
  quantity: 'LineItems-quantity',
  price: 'LineItems-price',
  priceOld: 'LineItems-priceOld',
  priceCompare: 'LineItems-priceCompare',
  badge: 'LineItems-badge',
}

export const defaultCheckoutClassNames: CheckoutClassNames = {
  root: 'Checkout',
  error: 'Checkout-error',
  overview: 'Checkout-overview',
  step: {
    root: 'Step',
    edit: 'Step-edit',
  },
  stepAddress: {
    summary: 'StepAddress-summary',
  },
  stepShipping: {
    summary: 'StepShipping-summary',
    dealerWarning: 'StepShipping-dealerWarning',
    dealerChooser: {
      root: 'DealerChooser',
      selected: 'selected',
      mapInfoWindow: 'DealerChooser-mapInfoWindow',
      mapMarker: 'DealerChooser-mapMarker',
    },
    homeDeliveryChooser: 'StepShipping-homeDeliveryChooser',
  },
  stepPayment: {
    root: 'StepPayment',
    termsMessage: 'StepPayment-termsMessage',
  },
  form: {
    root: 'Form',
    loading: 'Form-loading',
    submitButton: 'Form-submitButton',
    controls: 'Form-controls',
    actions: 'Form-actions',
    error: 'Form-error',
    control: 'Form-control',
    input: 'Form-input',
    switch: 'Form-switch',
    options: 'Form-options',
    option: 'Form-option',
    subFields: 'Form-subFields',
    label: 'Form-label',
    switchInput: 'Form-switchInput',
    apiValidated: 'Form-isValidated',
  },
}

export const defaultPriceClassNames: PriceClassNames = {
  root: 'Price',
  compare: 'Price-compare',
  current: 'Price-current',
}

export const defaultCartSkeletonClassnames: CartSkeletonClassnames = {
  root: 'CartSkeleton',
  item: 'CartSkeleton-item',
  overview: 'CartSkeleton-overview',

  text: 'CartSkeleton-text',
  button: 'CartSkeleton-button',
  select: 'CartSkeleton-select',
}

export const defaultMoneyClassNames: MoneyClassNames = {
  root: 'Money',
  currency: 'Money-currency',
  literal: 'Money-literal',
  integer: 'Money-integer',
  decimal: 'Money-decimal',
  fraction: 'Money-fraction',
  free: 'Money-free',
}

export const defaultClassNames: ClassNames = {
  addToCartButton: defaultAddToCartButtonClassNames,
  availability: defaultAvailabilityClassNames,
  cart: defaultCartClassNames,
  cartCounter: defaultCartCounterClassNames,
  cartQuickView: defaultCartQuickViewClassNames,
  lineItems: defaultLineItemsClassnames,
  checkout: defaultCheckoutClassNames,
  price: defaultPriceClassNames,
  cartSkeleton: defaultCartSkeletonClassnames,
  money: defaultMoneyClassNames,
  loader: defaultLoaderClassNames,
  giftCardOrCouponCodeForm: defaultCouponCodeFormClassNames,
  priceTable: defaultPriceTableClassNames,
}
